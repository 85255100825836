import React from "react"
import { css } from "@emotion/core"

import Layout from "../components/layout/"
import Head from "../components/head"
import PageHeading from "../components/pageHeading"
import MiniHeading from "../components/miniHeading"
import Text from "../components/Text"
import Markdown from "../components/Markdown"

import acknowledgements from "../cms/acknowledgements"

export default () => {
  let categorizedList = []
  let addedCategories = [];

  acknowledgements.people.forEach(person => {
    const { name, reason:category } = person
    // reason is is aliased to category because it's legacy code

    if(!addedCategories.includes(category)) {
      categorizedList.push({
        category,
        people: []
      })
      addedCategories.push(category)
    }

    const correctCategory = categorizedList.find(entry => entry.category === category)
    correctCategory.people.push(name)
  })

  return (
    <Layout>
      <Head title="Acknowledgements" />
  
      <PageHeading text="Acknowledgements" centered />

      <Markdown
        css={css`
          text-align: center;
          margin: 0 auto;
          margin-bottom: 3rem;
          margin-top: -.5rem;
          max-width: 40rem;
        `}
        raw={acknowledgements.text}
      />
  
      {categorizedList.map(category => (
        <div
          key={category.category}
          css={css`
            margin-bottom: 3rem;
          `}
        >
          <MiniHeading
            text={category.category}
            centered 
            style={css`
              font-size: 1.3rem;
            `}
          />

          {category.people.map(name => {
            const isBen = /Ben(.*)Borgers/i.test(name)

            return (
              <Text
                key={name}
                centered
                style={css`
                  margin-bottom: .6rem;
                `}
              >
                {isBen ? 
                
                `<a href="https://benborgers.com">${name}</a>`
                
                : name}
              </Text>
            )
          })}
        </div>
      ))}
    </Layout>
  )
}